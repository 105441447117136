import React from 'react';
import { authRoles } from 'app/auth';

const RegistedUserConfig = {
	settings: {
		layout: {
			config: { footer: false }
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/users',
			component: React.lazy(() => import('./RegisteredUsers'))
		}
	]
};

export default RegistedUserConfig;
