import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	cardData: { loading: false, data: {}, error: '' },
	perMonthData: { loading: false, data: {}, error: '' },
	transactionsData: { loading: false, data: {}, error: '' },
	selectedMonth: null,
	chartFilter: 'MONTH'
};

const dashboardSlice = createSlice({
	name: 'user/dashboard',
	initialState,
	reducers: {
		getCardDataBegin: state => {
			state.cardData.loading = true;
		},
		getCardDataSuccess: (state, { payload }) => {
			state.cardData.loading = false;
			state.cardData.data = payload;
		},
		getCardDataFailure: (state, { payload }) => {
			state.cardData.loading = false;
			state.cardData.error = payload;
		},

		getPerMonthDataBegin: state => {
			state.perMonthData.loading = true;
		},
		getPerMonthDataSuccess: (state, { payload }) => {
			state.perMonthData.loading = false;
			state.perMonthData.data = payload;
		},
		getPerMonthDataFailure: (state, { payload }) => {
			state.perMonthData.loading = false;
			state.perMonthData.error = payload;
		},

		getTransactionsDataBegin: state => {
			state.transactionsData.loading = true;
		},
		getTransactionsDataSuccess: (state, { payload }) => {
			state.transactionsData.loading = false;
			state.transactionsData.data = payload;
		},
		getTransactionsDataFailure: (state, { payload }) => {
			state.transactionsData.loading = false;
			state.transactionsData.error = payload;
		},

		setCurrentMonth: (state, { payload }) => {
			state.selectedMonth = payload;
		},
		setChartFilter: (state, { payload }) => {
			state.chartFilter = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	resetState,
	getCardDataBegin,
	getCardDataFailure,
	getCardDataSuccess,
	getPerMonthDataBegin,
	getPerMonthDataFailure,
	getPerMonthDataSuccess,
	getTransactionsDataBegin,
	getTransactionsDataFailure,
	getTransactionsDataSuccess,
	setCurrentMonth,
	setChartFilter
} = dashboardSlice.actions;

export const getCardData = createAsyncThunk('document/get-card-data', async (params, { dispatch }) => {
	dispatch(getCardDataBegin());
	try {
		const { data } = await http({
			url: '/users/dashboard',
			token: true,
			service: 'cms'
		});
		dispatch(getCardDataSuccess(data));
	} catch (error) {
		dispatch(getCardDataFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getDataPerMonth = createAsyncThunk('document/get-data-per-month', async (params, { dispatch }) => {
	dispatch(getPerMonthDataBegin());
	try {
		const { data } = await http({
			url: '/users/transactions/closed',
			token: true,
			options: { params },
			service: 'cms'
		});
		dispatch(getPerMonthDataSuccess(data));
	} catch (error) {
		dispatch(getPerMonthDataFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getChartData = createAsyncThunk('document/get-chart', async (params, { dispatch }) => {
	dispatch(getTransactionsDataBegin());
	try {
		const { data } = await http({
			url: '/users/transaction/count',
			token: true,
			options: { params },
			service: 'cms'
		});
		dispatch(getTransactionsDataSuccess(data));
	} catch (error) {
		dispatch(getTransactionsDataFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export default dashboardSlice.reducer;
