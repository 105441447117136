import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	orderBy: 'createdAt',
	order: 'desc',
	user: {
		response: {},
		loading: false,
		error: ''
	},
	transactions: {
		searchTerm: '',
		page: 0,
		rowsPerPage: 10,
		orderBy: 'purchasePrice',
		order: 'desc',
		list: {
			response: {},
			loading: false,
			error: ''
		}
	},
	selectedUser: '',
	changeUserStatus: { response: {}, loading: false, error: '' },
	updateUser: { response: {}, loading: false, error: '' }
};

const userSlice = createSlice({
	name: 'user/early-signup',
	initialState,
	reducers: {
		setSearchTerm: (state, { payload }) => {
			state.searchTerm = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setRowsPerPage: (state, { payload }) => {
			state.rowsPerPage = payload;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		setOrder: (state, { payload }) => {
			state.order = payload;
		},
		getRegisteredUserBegin: state => {
			state.user.loading = true;
		},
		getRegisteredUserSuccess: (state, { payload }) => {
			state.user.loading = false;
			state.user.response = payload;
		},
		getRegisteredUserFailure: (state, { payload }) => {
			state.user.loading = false;
		},
		setSelectedUser: (state, { payload }) => {
			state.selectedUser = payload;
		},
		getTransactionsBegin: state => {
			state.transactions.list.loading = true;
		},
		getTransactionsSuccess: (state, { payload }) => {
			state.transactions.list.loading = false;
			state.transactions.list.response = payload;
		},
		getTransactionsFailure: (state, { payload }) => {
			state.transactions.list.loading = false;
			state.transactions.list.error = payload;
		},

		setTransactionsPage: (state, { payload }) => {
			state.transactions.page = payload;
		},
		setTransactionsRowsPerPage: (state, { payload }) => {
			state.transactions.rowsPerPage = payload;
		},
		setTransactionsOrderBy: (state, { payload }) => {
			state.transactions.orderBy = payload;
		},
		setTransactionsOrder: (state, { payload }) => {
			state.transactions.order = payload;
		},

		changeUserStatusBegin: state => {
			state.changeUserStatus.loading = true;
		},
		changeUserStatusSuccess: (state, { payload }) => {
			state.changeUserStatus.loading = false;
			state.changeUserStatus.response = payload;
		},
		changeUserStatusFailure: (state, { payload }) => {
			state.changeUserStatus.loading = payload;
			state.changeUserStatus.error = payload;
		},

		updateUserBegin: state => {
			state.updateUser.loading = true;
		},
		updateUserSuccess: (state, { payload }) => {
			state.updateUser.loading = false;
			state.updateUser.response = payload;
		},
		updateUserFailure: (state, { payload }) => {
			state.updateUser.loading = payload;
			state.updateUser.error = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	setSearchTerm,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	getRegisteredUserBegin,
	getRegisteredUserSuccess,
	getRegisteredUserFailure,
	resetState,
	getTransactionsBegin,
	getTransactionsFailure,
	getTransactionsSuccess,
	setSelectedUser,
	setTransactionsOrder,
	setTransactionsOrderBy,
	setTransactionsPage,
	setTransactionsRowsPerPage,
	changeUserStatusBegin,
	changeUserStatusFailure,
	changeUserStatusSuccess,
	updateUserBegin,
	updateUserSuccess,
	updateUserFailure
} = userSlice.actions;

export const getRegisteredUser = createAsyncThunk('users/registered-users', async (params, { dispatch }) => {
	dispatch(getRegisteredUserBegin());
	try {
		const { data } = await http({ url: '/users', options: { params }, token: true, service: 'cms' });
		dispatch(getRegisteredUserSuccess(data));
	} catch (error) {
		dispatch(getRegisteredUserFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getTransactionList = createAsyncThunk('users/transaction-list', async (params, { dispatch }) => {
	dispatch(getTransactionsBegin());
	try {
		const { data } = await http({
			url: '/users/transactionsList',
			options: { params },
			token: true,
			service: 'cms'
		});
		dispatch(getTransactionsSuccess(data));
	} catch (error) {
		dispatch(getTransactionsFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const changeUserStatus = createAsyncThunk(
	'users/user-status-change',
	async ({ userId, payload, successCB = () => {} }, { dispatch }) => {
		dispatch(changeUserStatusBegin());
		try {
			const { data } = await http({
				url: `/users/update/${userId}`,
				method: 'patch',
				payload,
				token: true
			});
			dispatch(changeUserStatusSuccess(data));
			successCB();
		} catch (error) {
			dispatch(changeUserStatusFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const updateUser = createAsyncThunk(
	'users/user-update',
	async ({ payload, successCB = () => {} }, { dispatch }) => {
		dispatch(updateUserBegin());
		try {
			const { data } = await http({
				url: `/users`,
				method: 'post',
				payload,
				token: true,
				service: 'cms'
			});
			dispatch(updateUserSuccess(data));
			successCB();
			dispatch(
				showMessage({
					message: data?.message || 'User updated successfully!',
					variant: 'success'
				})
			);
		} catch (error) {
			dispatch(updateUserFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default userSlice.reducer;
