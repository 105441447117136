import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { http, downloadExcelFile } from 'lib';
import { showMessage } from 'app/store/fuse/messageSlice';
import { closeDialog } from './fuse/dialogSlice';

const initialState = {
	exportToExcel: {
		loading: false,
		error: ''
	},
	deleteActions: { loading: false, error: '', response: {} }
};

const commonSlice = createSlice({
	name: 'common Slice',
	initialState,
	reducers: {
		exportToExcelBegin: state => {
			state.exportToExcel.loading = true;
		},
		exportToExcelSuccess: state => {
			state.exportToExcel.loading = false;
		},
		exportToExcelFailure: (state, { payload }) => {
			state.exportToExcel.loading = false;
			state.exportToExcel.error = payload;
		},
		deleteActionBegin: state => {
			state.deleteActions.loading = true;
		},
		deleteActionSuccess: (state, { payload }) => {
			state.deleteActions.loading = false;
			state.deleteActions.response = false;
		},
		deleteActionFailure: (state, { payload }) => {
			state.deleteActions.loading = false;
			state.deleteActions.error = false;
		}
	}
});

export const {
	exportToExcelBegin,
	exportToExcelSuccess,
	exportToExcelFailure,
	deleteActionBegin,
	deleteActionSuccess,
	deleteActionFailure
} = commonSlice.actions;

export const exportToExcel = createAsyncThunk(
	'export-to-excel',
	async ({ url, name, service = 'cms' }, { dispatch }) => {
		dispatch(exportToExcelBegin());
		try {
			const { data } = await http({
				url,
				options: { responseType: 'arraybuffer', headers: { 'Content-Type': 'blob' } },
				token: true,
				service
			});
			downloadExcelFile(data, name);
			dispatch(exportToExcelSuccess());
		} catch (error) {
			dispatch(exportToExcelFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const deleteAction = createAsyncThunk(
	'delete-action',
	async ({ url, method, payload = null, service = 'cms', successCB = () => {} }, { dispatch }) => {
		dispatch(deleteActionBegin());
		try {
			const response = await http({
				url,
				method,
				payload,
				token: true,
				service
			});
			dispatch(deleteActionSuccess(response));
			dispatch(closeDialog());
			successCB(response);
		} catch (error) {
			dispatch(deleteActionFailure(error?.response?.data?.message));
			dispatch(closeDialog());
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default commonSlice.reducer;
