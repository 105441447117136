import React from 'react';
import { authRoles } from 'app/auth';

const TransactionsConfig = {
	settings: {
		layout: {
			config: { footer: { display: false } }
		}
	},
	auth: authRoles.admin,
	routes: [
		{
			path: '/transactions-management',
			component: React.lazy(() => import('./Transactions'))
		}
	]
};

export default TransactionsConfig;
