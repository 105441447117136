import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	orderBy: 'createdAt',
	order: 'desc',
	activity: {
		response: {},
		loading: false,
		error: ''
	},
	stage: {
		response: {},
		loading: false,
		error: ''
	}
};

const activitySlice = createSlice({
	name: 'activities',
	initialState,
	reducers: {
		setSearchTerm: (state, { payload }) => {
			state.searchTerm = payload;
		},
		setPage: (state, { payload }) => {
			state.page = payload;
		},
		setRowsPerPage: (state, { payload }) => {
			state.rowsPerPage = payload;
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload;
		},
		setOrder: (state, { payload }) => {
			state.order = payload;
		},
		getActivityBegin: state => {
			state.activity.loading = true;
		},
		getActivitySuccess: (state, { payload }) => {
			state.activity.loading = false;
			state.activity.response = payload;
		},
		getActivityFailure: (state, { payload }) => {
			state.activity.loading = false;
			state.activity.error = payload;
		},
		getStageBegin: state => {
			state.stage.loading = true;
		},
		getStageSuccess: (state, { payload }) => {
			state.stage.loading = false;
			state.stage.response = payload;
		},
		getStageFailure: (state, { payload }) => {
			state.stage.loading = false;
			state.stage.error = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	setSearchTerm,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	getActivityBegin,
	getActivityFailure,
	getActivitySuccess,
	getStageBegin,
	getStageFailure,
	getStageSuccess,
	resetState
} = activitySlice.actions;

export const getActivities = createAsyncThunk('all-activity', async (params, { dispatch }) => {
	dispatch(getActivityBegin());
	try {
		const { data } = await http({
			url: '/activity',
			options: { params },
			token: true,
			service: 'cms'
		});
		dispatch(getActivitySuccess(data?.data));
	} catch (error) {
		dispatch(getActivityFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const getActivitiesStage = createAsyncThunk('all-stage-activity', async (params, { dispatch }) => {
	dispatch(getStageBegin());
	try {
		const { data } = await http({
			url: '/activity/stages',
			options: { params },
			token: true,
			service: 'cms'
		});
		dispatch(getStageSuccess(data));
	} catch (error) {
		dispatch(getStageFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export const addActivity = createAsyncThunk('add-activity', async ({ payload, successCB = () => {} }, { dispatch }) => {
	dispatch(getActivityBegin());
	try {
		const { data } = await http({
			url: '/activity',
			token: true,
			payload,
			service: 'cms',
			method: 'post'
		});
		successCB(data);
	} catch (error) {
		dispatch(getActivityFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});
export const updateActivity = createAsyncThunk(
	'update-activity',
	async ({ activityId, payload, successCB = () => {} }, { dispatch }) => {
		dispatch(getActivityBegin());
		try {
			const { data } = await http({
				url: `/activity/${activityId}`,
				token: true,
				payload,
				service: 'cms',
				method: 'patch'
			});
			successCB(data);
		} catch (error) {
			dispatch(getActivityFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export default activitySlice.reducer;
