import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'item',
		icon: 'dashboard',
		url: '/dashboard'
	},
	{
		id: 'analytics',
		title: 'Analytics & Reports',
		type: 'item',
		icon: 'analytics',
		url: '/analytics'
	},
	// {
	// 	id: 'documents-library-management',
	// 	title: 'Documents Library Management',
	// 	type: 'item',
	// 	icon: 'article',
	// 	url: '/documents'
	// },
	{
		id: 'Users',
		title: 'Users Management',
		type: 'item',
		icon: 'group',
		url: '/users',
		exact: true
	},

	{
		id: 'transactions',
		title: 'Transactions Management',
		type: 'item',
		icon: 'receipt_long',
		url: '/transactions-management',
		exact: true
	},
	{
		id: 'activities',
		title: 'Activity Management',
		type: 'item',
		icon: 'receipt_long',
		url: '/activities-management',
		exact: true
	},
	{
		id: 'Early Access Beta',
		title: 'Early Access Beta',
		type: 'collapse',
		icon: 'visibility',
		children: [
			{
				id: 'Signup Request',
				title: 'Signup Request',
				type: 'item',
				icon: 'group_add',
				url: '/users/early-beta-signup'
			},
			{
				id: 'Enquiry',
				title: 'Enquiry',
				type: 'item',
				icon: 'help_outline',
				url: '/users/enquiry'
			}
		]
	}
];

export default navigationConfig;
