import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	selectedRange: {},
	analyticsData: { loading: false, data: {}, error: '' }
};

const analyticsSlice = createSlice({
	name: 'user/early-signup',
	initialState,
	reducers: {
		setCustomDateRange: (state, { payload }) => {
			state.selectedRange = payload;
		},
		getAnalyticsDataBegin: state => {
			state.analyticsData.loading = true;
		},
		getAnalyticsDataSuccess: (state, { payload }) => {
			state.analyticsData.loading = false;
			state.analyticsData.data = payload;
		},
		getAnalyticsDataFailure: (state, { payload }) => {
			state.analyticsData.loading = false;
			state.analyticsData.error = payload;
		},
		resetState: () => ({
			...initialState
		})
	}
});

export const {
	getAnalyticsDataBegin,
	getAnalyticsDataFailure,
	getAnalyticsDataSuccess,
	setCustomDateRange,
	resetState
} = analyticsSlice.actions;

export const getAnalyticsData = createAsyncThunk('document/get-analytics-data', async (params, { dispatch }) => {
	dispatch(getAnalyticsDataBegin());
	try {
		const { data } = await http({
			url: '/users/analytics',
			options: { params },
			token: true,
			service: 'cms'
		});
		dispatch(getAnalyticsDataSuccess(data));
	} catch (error) {
		dispatch(getAnalyticsDataFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

export default analyticsSlice.reducer;
