import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { http } from 'lib';

const initialState = {
	searchTerm: '',
	page: 0,
	rowsPerPage: 10,
	uploadLibraryDocument: {
		loading: false,
		response: {},
		error: ''
	},
	addLibraryDocument: {
		loading: false,
		response: {},
		error: ''
	},
	libraryDocumentList: {
		loading: false,
		response: {},
		error: ''
	},
	deleteDocument: {
		loading: false,
		response: {},
		error: ''
	},
	searchDocuments: {
		loading: false,
		response: {},
		error: ''
	},
	dataToEdit: {}
};
const documentsSlice = createSlice({
	name: 'documents',
	initialState,
	reducers: {
		resetState: () => ({
			...initialState
		}),
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload;
		},
		setDataToEdit: (state, action) => {
			state.dataToEdit = action.payload;
		},
		uploadLibraryDocumentBegin: state => {
			state.uploadLibraryDocument.loading = true;
		},
		uploadLibraryDocumentSuccess: (state, action) => {
			state.uploadLibraryDocument.loading = false;
			state.uploadLibraryDocument.response = action.payload;
		},
		uploadLibraryDocumentFailure: (state, action) => {
			state.uploadLibraryDocument.loading = false;
			state.uploadLibraryDocument.error = action.payload;
		},
		addLibraryDocumentBegin: state => {
			state.addLibraryDocument.loading = true;
		},
		addLibraryDocumentSuccess: (state, action) => {
			state.addLibraryDocument.loading = false;
			state.addLibraryDocument.response = action.payload;
		},
		addLibraryDocumentFailure: (state, action) => {
			state.addLibraryDocument.loading = false;
			state.addLibraryDocument.error = action.payload;
		},
		getLibraryDocumentListBegin: state => {
			state.libraryDocumentList.loading = true;
		},
		getLibraryDocumentListSuccess: (state, action) => {
			state.libraryDocumentList.loading = false;
			state.libraryDocumentList.response = action.payload;
		},
		getLibraryDocumentListFailure: (state, action) => {
			state.libraryDocumentList.loading = false;
			state.libraryDocumentList.error = action.payload;
		},
		deleteDocumentBegin: state => {
			state.deleteDocument.loading = true;
		},
		deleteDocumentSuccess: (state, action) => {
			state.deleteDocument.loading = false;
			state.deleteDocument.response = action.payload;
		},
		deleteDocumentFailure: (state, action) => {
			state.deleteDocument.loading = false;
			state.deleteDocument.error = action.payload;
		},
		searchDocumentBegin: state => {
			state.libraryDocumentList.loading = true;
			state.searchDocuments.loading = true;
		},
		searchDocumentSuccess: (state, action) => {
			state.searchDocuments.loading = false;
			state.libraryDocumentList.loading = false;
			state.searchDocuments.response = action.payload;
			state.libraryDocumentList.response = action.payload;
		},
		searchDocumentFailure: (state, action) => {
			state.searchDocuments.loading = false;
			state.libraryDocumentList.loading = false;
			state.searchDocuments.error = action.payload;
			state.libraryDocumentList.error = action.payload;
		}
	}
});

export const {
	setSearchTerm,
	setPage,
	resetState,
	setRowsPerPage,
	setDataToEdit,
	uploadLibraryDocumentBegin,
	uploadLibraryDocumentSuccess,
	uploadLibraryDocumentFailure,
	addLibraryDocumentBegin,
	addLibraryDocumentSuccess,
	addLibraryDocumentFailure,
	getLibraryDocumentListBegin,
	getLibraryDocumentListSuccess,
	getLibraryDocumentListFailure,
	deleteDocumentBegin,
	deleteDocumentSuccess,
	deleteDocumentFailure,
	searchDocumentBegin,
	searchDocumentSuccess,
	searchDocumentFailure
} = documentsSlice.actions;

export const addOrUpdateLibraryDocument = createAsyncThunk(
	'library-document/add-or-update',
	async ({ requestBody, documentUrl, method, reCall = false }, { dispatch, getState }) => {
		dispatch(addLibraryDocumentBegin());
		try {
			const payload = {
				_id: documentUrl.libraryDocId,
				title: requestBody.title,
				description: requestBody.description,
				url: documentUrl.key
			};
			const response = await http({ method, url: '/library/document', payload, token: true, service: 'cms' });
			const { page, rowsPerPage } = getState().documents;
			dispatch(addLibraryDocumentSuccess(response.data));
			dispatch(closeDialog());
			dispatch(setDataToEdit({}));

			if (reCall) {
				dispatch(
					getLibraryDocumentList({
						offset: page,
						limit: rowsPerPage
					})
				);
			} else {
				dispatch(setPage(0));
				if (page === 0) {
					dispatch(
						getLibraryDocumentList({
							offset: 0,
							limit: rowsPerPage
						})
					);
				}
			}

			dispatch(
				showMessage({
					message: response?.data?.message || 'Document added successfully!',
					variant: 'success'
				})
			);
		} catch (error) {
			dispatch(addLibraryDocumentFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

const uploadLibraryDocument = createAsyncThunk(
	'library-document/upload',
	async ({ requestBody, documentUrl, method }, { dispatch }) => {
		try {
			const options = {
				method: 'PUT',
				body: requestBody.document
			};
			await fetch(documentUrl.url, options);
			dispatch(
				addOrUpdateLibraryDocument({
					requestBody: { title: requestBody.title, description: requestBody.description },
					documentUrl,
					method
				})
			);
		} catch (error) {
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getLibraryDocumentUrl = createAsyncThunk(
	'document/get-url',
	async ({ requestBody, requestUrl, method, service = 'cms' }, { dispatch }) => {
		dispatch(addLibraryDocumentBegin());
		dispatch(uploadLibraryDocumentBegin());
		try {
			const { data } = await http({ url: requestUrl, token: true, service });
			dispatch(uploadLibraryDocument({ requestBody, documentUrl: data, method }));
			dispatch(uploadLibraryDocumentSuccess(data));
		} catch (error) {
			dispatch(addLibraryDocumentFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const getLibraryDocumentList = createAsyncThunk(
	'document/get--library-document-list',
	async (params, { dispatch }) => {
		dispatch(getLibraryDocumentListBegin());
		try {
			const { data } = await http({
				url: '/library/document',
				options: { params },
				token: true,
				service: 'cms'
			});
			dispatch(getLibraryDocumentListSuccess(data));
		} catch (error) {
			dispatch(getLibraryDocumentListFailure(error?.response?.data?.message));
			dispatch(
				showMessage({
					message: error?.response?.data?.message,
					variant: 'error'
				})
			);
		}
	}
);

export const deleteDocument = createAsyncThunk('delete-document', async (params, { dispatch, getState }) => {
	dispatch(deleteDocumentBegin());
	try {
		const { data } = await http({
			method: 'delete',
			url: `/library/document/${params.id}`,
			token: true,
			service: 'cms'
		});
		const { page, rowsPerPage } = getState().documents;
		dispatch(
			showMessage({
				message: data.message,
				variant: 'success'
			})
		);
		dispatch(deleteDocumentSuccess(data));
		dispatch(setPage(0));
		if (page === 0) {
			dispatch(
				getLibraryDocumentList({
					offset: 0,
					limit: rowsPerPage
				})
			);
		}
	} catch (error) {
		dispatch(deleteDocumentFailure(error?.response?.data?.message));
		dispatch(
			showMessage({
				message: error?.response?.data?.message,
				variant: 'error'
			})
		);
	}
});

// export const searchDocument = createAsyncThunk('search-document', async (params, { dispatch, getState }) => {
// 	dispatch(searchDocumentBegin());
// 	try {
// 		const { data } = await http('get', `/library/document/search`, null, { headers, params }, true);
// 		dispatch(searchDocumentSuccess(data));
// 	} catch (error) {
// 		dispatch(searchDocumentFailure(error?.response?.data?.message));
// 		dispatch(
// 			showMessage({
// 				message: error?.response?.data?.message,
// 				variant: 'error'
// 			})
// 		);
// 	}
// });
export default documentsSlice.reducer;
