import { authRoles } from 'app/auth';
import React from 'react';

const Error404PageConfig = {
	settings: {
		layout: {
			config: { navbar: false, header: false, toolbar: false, footer: false }
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/404',
			component: React.lazy(() => import('./Error404Page'))
		}
	]
};

export default Error404PageConfig;
