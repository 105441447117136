import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import DashboardConfig from 'app/main/dashboard/DashboardConfig';
import LoginConfig from 'app/main/login/LoginConfig';
// import RegisterConfig from 'app/main/register/RegisterConfig';
// import ForgotPasswordConfig from 'app/main/forgot-password/ForgotPasswordConfig';
// import ResetPasswordConfig from 'app/main/reset-password/ResetPasswordConfig';
import DocumentsConfig from 'app/main/documents/DocumentsConfig';
import Error404PageConfig from 'app/main/404/Error404PageConfig';
import EarlySignupConfig from 'app/main/EarlySignup/EarlySignupConfig';
import EnquiryConfig from 'app/main/Enquiry/EnquiryConfig';
import RegistedUserConfig from 'app/main/RegisteredUsers/RegisteredUsersConfig';
import TransactionsConfig from 'app/main/transactions/TransactionsConfig';
import AnalyticsConfig from 'app/main/analytics/AnalyticsConfig';
import ActivitiesConfig from 'app/main/activities/ActivitiesConfig';

const routeConfigs = [
	LoginConfig,
	// RegisterConfig,
	// ForgotPasswordConfig,
	// ResetPasswordConfig,
	DashboardConfig,
	AnalyticsConfig,
	ActivitiesConfig,
	DocumentsConfig,
	EarlySignupConfig,
	EnquiryConfig,
	RegistedUserConfig,
	TransactionsConfig,
	Error404PageConfig
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/dashboard" />
	},
	{
		path: '*',
		component: () => <Redirect to="/404" />
	}
];

export default routes;
