export { http, getQueryString } from './http';
export { getDate, getTime } from './getDateAndTime';
export { downloadExcelFile, downloadFile } from './downloadFile';
export { txStatus, userRoles } from './transaction';

export function cleanObject(obj) {
	Object.keys(obj).forEach(key => {
		if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
			delete obj[key];
		}
	});
	return obj;
}

export function getFormattedMonth(month = new Date().getMonth() + 1) {
	return month < 10 ? `0${month}` : `${month}`;
}

export const MonthOptions = [
	{
		abbreviation: 'Jan',
		name: 'January',
		value: '01'
	},
	{
		abbreviation: 'Feb',
		name: 'February',
		value: '02'
	},
	{
		abbreviation: 'Mar',
		name: 'March',
		value: '03'
	},
	{
		abbreviation: 'Apr',
		name: 'April',
		value: '04'
	},
	{
		abbreviation: 'May',
		name: 'May',
		value: '05'
	},
	{
		abbreviation: 'Jun',
		name: 'June',
		value: '06'
	},
	{
		abbreviation: 'Jul',
		name: 'July',
		value: '07'
	},
	{
		abbreviation: 'Aug',
		name: 'August',
		value: '08'
	},
	{
		abbreviation: 'Sep',
		name: 'September',
		value: '09'
	},
	{
		abbreviation: 'Oct',
		name: 'October',
		value: '10'
	},
	{
		abbreviation: 'Nov',
		name: 'November',
		value: '11'
	},
	{
		abbreviation: 'Dec',
		name: 'December',
		value: '12'
	}
];

export function numberWithCommas(x) {
	return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
